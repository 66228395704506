.header {
    display: flex;
    flex-direction: row;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    height: 56px;
    width: 100%;
    top: 0;
    z-index: 5;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.headerLogoB2B{
    height: 40px;
    cursor: pointer;
    @include font-title()
}

.headerLogoGleeph{
    height: 30px;
}