.backgroundTopBook {
    height: 347px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    @include for-phone-only{
        height: 192px;
    }
}

.containerTopBook {
    display: flex;
    flex-direction: row;
    padding: 32px 32px;
    @include for-phone-only{
        padding: 16px 16px;
    }
}

.containerBookCover {
    display: flex;
    align-items: flex-end;
}

.sizeBookCover {
    width: 170px;
    height: 243px;
    @include for-phone-only{
        width: 84px;
        height: 120px;
    }
}

.containerInfoBook {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 38px;
    padding-top: 33px;
    filter: drop-shadow(0px 2.08333px 5.20833px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0px 20.8333px rgba(0, 0, 0, 0.1));
    @include for-phone-only{
        padding-left: 15px;
        padding-top: 0px;
    }
}


.titleShelf {
    width: 80%;
    @include font-title(
        $font-style: italic, 
        $font-size: 32px, 
        $font-size-phone: 20px, 
        $color: #ffffff, 
        $text-align : left);
    @include for-phone-only{
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}

.infoBookPolice {
    @include font-sub-title(
        $font-size: 16px, 
        $font-size-phone : 12px,
        $text-align : left,
        $color: #e3e3e3,
        $line-height: 160%);
    @include for-phone-only{
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
}

.clilContainer {
    display: flex;
    align-items: center;
    padding: 4px 5px 4px 4px;
    border-radius: 2.08333px;
    margin-right: auto;
}

.bookScreen-iconAuthor {
    height: 20px;
    width: 20px;
    @include for-phone-only{
        height: 15px;
        width: 15px;
    }
}


.typeBookPolice {
    @include font-sub-title(
        $font-weight: 500, 
        $font-size: 19px, 
        $font-size-phone: 12px, 
        $color: #ffffff,
        $line-height: 14px);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-shadow: 0px 2.41333px 6.03333px rgba(0, 0, 0, 0.08),
      0px 0px 24.1333px rgba(0, 0, 0, 0.1);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

// LOADING SCSS
.sizeBookCover-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #252525
}

.bookscreen-cover-loading {
    height: 60px;
    width: 83.33px;
    @include for-phone-only{
        height: 30px;
        width: 41.8px;
    }
}

.titleBook-loading-up {
    opacity: 0.75;
    border-bottom: 24px solid #ffffff;
    width: 80%;
    margin-bottom: 16px;
    @include for-phone-only{
        border-bottom: 16px solid #ffffff;
        width: 40vw;
        margin-bottom: 12px;
    }
}

.titleBook-loading-down {
    opacity: 0.75;
    border-bottom: 24px solid #ffffff;
    width: 60%;
    margin-bottom: 21px;
    @include for-phone-only{
        border-bottom: 16px solid #ffffff;
        width: 30vw;
        margin-bottom: 10px;
    }
}

.backgroundbook-police-loading {
    background-color: #e3e3e3;
    opacity: 0.5;
    color: #e3e3e3;
    height: 10%;
    width: 30%;
    @include for-phone-only{
        width: 20vw;
        margin-bottom: 2px;
    }
}

.clilContainer-loading {
    width: 30%;
    display: flex;
    padding: 4px 5px 4px 4px;
    border-radius: 2.08333px;
    margin-right: auto;
    background-color: #D1C8B3
}
