.backgroundGrey {
  height: 100%;
  min-height: calc(100vh - 50px);
  background-color: #e3e3e3;
}

.fixWidth {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  @include for-phone-only {
      max-width: 100%;
    }
}

.backgroundListBookInShelf {
  min-height: 100vh;
  height: auto;
  flex-direction: column;
  background-color: #f7f7f7;
}

section {
  margin: 0px 24px;
}

.separateLineStyle {
  height: 1px;
  border-bottom: 1px solid #e3e3e3;
  width: 100%;
  align-self: center;
}

.italic {
  font-style: italic;
}

.flexAlignCentJustifyBet{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.listResultOpacity{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0,0,0,0.25);  
  display: flex;
}
  

.center {
  text-align: center;
}
