/*** FONTS ***/
@import url(https://fonts.googleapis.com/css?family=Montserrat:900|Raleway:400,400i,700,700i);
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Vollkorn:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:200,200i,400,500|Vollkorn:400,400i&amp;display=swap");

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #595959;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #17a2b8;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}


// Global
@import "./variables";
@import "./mixins";

// Components
@import "./components/back.scss";
@import "./components/footerButton.scss";
@import "./components/autocompletionComp.scss";
@import "./components/placeholderHome.scss";
@import "./components/coupDeCoeur.scss";
@import "./components/workHorizontalList.scss";
@import "./components/defaultCover.scss";
@import "./components/footerB2B.scss";
@import "./components/footerGleeph.scss";
@import "./components/mentionsLegales.scss";
@import "./components/header.scss";
@import "./components/resultWithoutReco.scss";
@import "./components/loading.scss";
@import "./components/titleReco.scss";
@import "./components/newSearch.scss";
@import "./components/bookSearch.scss"; 
@import "./components/priceAndPublisher.scss"; 
@import "./components/recoBooksComp.scss"; 
@import "./components/bookList.scss"; 
@import "./components/backgroundBook.scss"; 
@import "./components/authorPanel.scss"; 
@import "./components/popularity.scss"; 
@import "./components/tags.scss"; 
@import "./components/resumePanel.scss"; 
@import "./components/readMore.scss"; 
@import "./components/commentOnWork.scss"; 
@import "./components/commentHorizontalList.scss"; 
@import "./components/button.scss"; 
@import "./components/shareComp.scss"; 
@import "./components/concours.scss"; 
@import "./components/waitingConcours.scss"; 
@import "./components/reglementConcours.scss"; 

// Pages
@import "./screens/home.scss";
@import "./screens/base.scss";
@import "./screens/bookDetail.scss";
@import "./screens/shareScreen.scss";
@import "./screens/legalNotice.scss";
@import "./screens/concoursScreen.scss";

// Animations
@import "./animations/animations.scss";

.itemColorBookSeach {
  color: #FFFFFF;
}

html,
body {
  margin: 0;
  padding: 0;
  background: #f7f7f7;
  height: 100%;
}

.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}  

*,
*:before,
*:after {
  box-sizing: border-box;
}

a:link {
    text-decoration: none;
    color: inherit;
}

.shareLinkEnabled {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08), 2px 2px 10px rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

.opacity5{
  opacity: 0.5;
}