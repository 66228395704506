.containerBookSearch {
    margin-top: 8px;
    margin-bottom: 16px;
    cursor: pointer;
}

.backgroundBookSearchBlur {
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
    border-radius: 8px;
}

.subContainerBookSearch{
    position: relative;
}

.containerBlurBookSearch {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    width: 100%;
    height: 116px;
    background: rgba(37, 37, 37, 0.5);
    border-radius: 8px 8px 8px 8px;
}

.blurCoverBookSearch {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(72px);
    background: rgba(37, 37, 37, 0.45);
}

.containerBlurBookSearchIndisponible {
    position: absolute;
    z-index: 2;
    overflow: hidden;
    width: 100%;
    height: 116px;
    background: rgba(37, 37, 37, 0.5);
    border-radius: 8px 8px 8px 8px;
}

.backgroundBookSearch {
    background: rgba(37, 37, 37, 0.45);
    border-radius: 8px 8px 8px 8px;
}

.containerBookSearchInBG {
    display: flex;
    flex-direction: row;
    padding: 8px;
    position: relative;
    z-index: 1;
}

.itemText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 16px;
}

.itemTitle,
.itemAuthor,
.itemClil,
.itemEditor {
  padding: 0;
  margin: 0;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.itemTitle {
    @include font-sub-title(
        $font-family: Vollkorn, 
        $font-style: italic, 
        $color: #252525,
        $text-align : left
    );
    letter-spacing: 0.02em;
    font-feature-settings: "lnum" on;
}

.itemAuthor {
    @include font-sub-title(
        $font-family: Vollkorn, 
        $text-align : left,
        $font-size: 14px,
        $line-height: 160%
    );
  }
  
.containerClil {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: auto;
    height: 18px;
    border-radius: 3px;
    margin-top: 4px;
    margin-bottom: 6px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}


.iconClil {
    height: 13px;
    width: 13px;
    margin-left: 5px;
}

.itemClil {
    @include font-sub-title(
        $font-size: 11px,
        $font-size-phone: 11px,  
        $line-height: 160%, 
        $color: #ffffff,
        $font-weight: normal
    );
    letter-spacing: 0.01em;
    text-transform: uppercase;
    font-feature-settings: "lnum" on;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 4px;
}
  