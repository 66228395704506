// ARROW
.arrowCoupdecoeurContainer {
    display: flex;
    align-items: center;
}

.arrowCoupdeCoeurIcon {
    transform: scaleX(-1);
}

// CARD
.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
    supported by Chrome, Edge, Opera and Firefox */
}

.bookscreen-coupdecoeur-listbook-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 16px;
    @include for-phone-only {
        padding-right: 8px;
    }  
}

.bookscreen-coupdecoeur-cover-style {
    height: 150px;
    width: 102.78px;
    @include for-phone-only {
        height: 108px;
        width: 74px;    
    }  
}

.bookscreen-similarbook-allcard-container {
    padding-left: 32px;
    @include for-phone-only {
        padding-left: 16px;
    }  
}

.bookscreen-similarbook-listbook-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 16px;
    padding-bottom: 16px;
    @include for-phone-only {
        padding-right: 8px;
    }  
}
  
.bookscreen-similarbook-cover-style {
    height: 150px;
    width: 102.78px;
    @include for-phone-only {
        height: 108px;
        width: 74px;    
    }  
}

.bookscreen-shelf-allcard-container {
    padding-left: 12px;
    @include for-phone-only{
        padding-left: 10px;
    }
}
  
.bookscreen-shelf-listbook-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 11.86px;
    @include for-phone-only{
        padding-right: 10.4px;
    }
}

.bookscreen-shelf-cover-style {
    height: 103px;
    width: 72px;
    @include for-phone-only{
        height: 89px;
        width: 61px;
    }   
}

