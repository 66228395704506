.containerTogglerShare {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.circleToggleShare {
    height: 16px;
    width: 16px;
    border-radius: 0%;
    background-color: #595959;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.circleToggleShareUnselected {
    height: 16px;
    width: 16px;
    border-radius: 0%;
    border: 1px solid #595959;
    flex-shrink: 0;
}

.ticShareImg {
    height: 8px;
}

.containerTextShare {
    margin-left: 8px;
}

.subTitleScreen {
    @include font-sub-title();
    margin: auto;
    width: 80%;
}

.containerShareScreen {
    margin-top: 16px;
    margin-bottom: 24px;
}

.containerTextInputShare {
    margin-top: 24px;
    margin-bottom: 24px;
}

.containerToggles {
    margin-bottom: 24px;
}

.borderShareLinkButtonSend {
    border: 1px solid #89DA8E;
    border-radius: 90px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 22px;
    max-width: 500px;
    margin: auto;
}

.submitBtnTextSend {
    @include font-sub-title($line-height: 19px, $color: #89DA8E);
    display: flex;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
}

.borderShareLinkButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px 12px 22px;
    background: #B2B2B2;
    border-radius: 90px;
    max-width: 500px;
    margin: auto;
}

.policeTextToggle{
    @include font-sub-title($font-size : 17px);
}

.titleShare{
    @include font-title( $line-height : 120%);
    margin-bottom:  16px;
}
