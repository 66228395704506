.containerListResultWoMargin {
    margin-top: 16px;
}

.midTextReco {
    @include font-title($font-size: 22px);
}

.logoJaimeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.imageJaime {
    margin-right: 8px;
}

.containerWorkHorizontalAndText {
    margin-bottom: 10px;
    margin-top: 14px;
}
  

.containerScrollVertical {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
} 