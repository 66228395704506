.placeholder_div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
}

.placeholder {
    max-width: 500px;
    width: 100%;
    margin-bottom: 23px;
}
  